<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>和我们，做有挑战的事！</div>
        </div>
      </div>
    </div>
    <div class="container-lg px-4 content py-5">
      <div class="col-12">
        <a
          @click="$router.go(-1)"
          role="button"
          class="btn btn-outline-primary mb-3"
        >
          返回列表
        </a>
      </div>
      <div class="col-12 title fs-1 fw-bold">全栈工程师</div>
      <div class="mt-3">
        <div class="badge border text-primary border-primary">开发</div>
        <div class="ms-2 badge border text-primary border-primary">
          社会招聘
        </div>
      </div>
      <div class="description mt-3">
        <div class="fs-4 fw-bold my-4">岗位描述</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              基于交易体系（金融类）需要定制开发项目特定需求；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              负责项目相关产品的设计、研发和维护；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              协调驱动团队间配合协作，能基于目标驱动优化产品和系统。
            </li>
          </ul>
        </div>
      </div>

      <div class="requirement mt-3">
        <div class="fs-4 fw-bold my-4">岗位要求</div>
        <div class="mt-2">
          <ul class="list-unstyled mb-4">
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              统招本科或以上学历，具备扎实的编程基础和数据结构算法基础，优秀的编程能力和问题解决能力；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              2年以上项目开发经验，具备核心业务系统或负责业务系统架构设计开发经验；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              有对工程化有较好理解，对网络、自动化测试、安全、性能有较好理解；
            </li>
            <li class="mb-2">
              <i class="bi bi-check text-primary"></i>
              精通Cpp/Rust及面向对象开发，对Vue/React、Webpack、Nodejs有深入理解，熟练掌握Linux/MySQL等。
            </li>
          </ul>
        </div>
      </div>

      <div class="location mt-3">
        <div class="fs-4 fw-bold my-4">工作城市</div>
        <div class="mt-2">
          <span> 上海 </span>
          <span> 北京 </span>
          <span> 深圳 </span>
          <span> 成都 </span>
          <span> 徐州 </span>
        </div>
      </div>

      <div class="mt-3">
        <div class="fs-4 fw-bold my-4">联系方式</div>
        <div class="mb-2">简历请发送至邮箱：recruit@ft.tech</div>
        <img
          src="../../assets/img/招聘求职.jpg"
          align="center"
        />
      </div>
      <div class="mt-3 d-flex justify-content-center">
        <a class="btn btn-primary px-5" href="mailto:recruit@ft.tech">
          简历投递
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Job6",
};
</script>
<style scoped lang="scss">
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/join/social_banner.jpg");
  background-position-x: 25%;
}
.container-lg {
  border-radius: 3rem;
}
a.btn {
  border-radius: 2rem;
}
img {
  width: 100px;
}
.cover-text > div {
  font-size: 3rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.75rem !important;
    text-align: center;
  }
}
</style>
